import {Injectable, NgZone} from '@angular/core';
import * as firebase from 'firebase';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { HttpClient } from '@angular/common/http';
import { CoreService } from './core.service';
import { FIREBASE_CONFIG } from '../../firebase.credentials';
import {first, map} from 'rxjs/operators';
import {TranslateService} from "@ngx-translate/core";
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public state: ReplaySubject<boolean> = new ReplaySubject(1);
  public user: ReplaySubject<firebase.User> = new ReplaySubject(1);
  public lang: ReplaySubject<string> = new ReplaySubject(1);
  private defaultLang = 'ru';
  useLang = 'ru';
  startUid;

  constructor(
    private db: AngularFireDatabase,
    private httpClient: HttpClient,
    private coreService: CoreService,
    private storage: Storage,
    private translateService: TranslateService,
    private zone: NgZone
  ) {

    this.startUid = Math.random().toString(16).slice(2);

    this.storage.ready().then(() => {
      this.storage.get('language').then(res => {
        console.log('Язык сохраненный', res);
        const lang = res || this.defaultLang;
        this.translateService.use(lang)
            .pipe(first())
            .subscribe(() => {
              this.lang.next(lang);
            });
      });
    });

    this.lang.subscribe(currentLang => {
      this.zone.run(() => {
        this.useLang = currentLang;
        this.translateService.use(currentLang);
      });
    });

    firebase.auth().onAuthStateChanged(authData => {
      if (authData != null) {
        console.log('onAuthStateChanged', authData.uid);
        this.state.next(true);
        this.user.next(authData);
      } else {
        // firebase.auth().signInAnonymously();
        this.state.next(false);
        this.user.next(null);
      }
    });
  }

  getCurrentUser() {
    return new Promise<any>((resolve, reject) => {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          resolve(user);
        } else {
          reject('No user logged in');
        }
      });
    });
  }

  saveLanguage(lang: string) {
    console.log(lang)
    console.log('Сохраним', lang);
    this.storage.set('language', lang);
    this.lang.next(lang);
  }

  guardAuth(): Observable<any> {
    const isAuth = new Subject();

    this.auth().then(() => {
      isAuth.next(true);
      isAuth.complete();
    });

    return isAuth;
  }


  isAdmin() {
    return this.db.object('setting/admin').valueChanges().pipe(
      map(user => {
        return this.getLogin() === user;
      })
    )
  }
  // авторизация пользователя
  // возвращает в промис логин пользователя
  async auth() {
    return new Promise((resolve, reject) => {
      this.state.subscribe(isAuth => {
        console.log(isAuth)
        if (isAuth) {
          resolve(this.getLogin());
        }
      });
    });
  }

  // Сохраненный логин авторизованного пользователя
  getLogin(): string {
    return firebase.auth().currentUser ? firebase.auth().currentUser.uid : '';
  }

  // Данные авторизованного пользователя
  getUser(): firebase.User {
    return firebase.auth().currentUser;
  }

  // Присоединение номера телефона к аккаунту
  linkAuthPhone(phone: string) {
    if (!phone) {
      this.coreService.presentToast('Введите номер телефона');
      return;
    }

    if (phone.substr(0, 1) === '+') {
      phone = phone.substr(1);
    }

    const server = `https://us-central1-${FIREBASE_CONFIG.projectId}.cloudfunctions.net`;
    const uid = firebase.auth().currentUser.uid;

    this.httpClient.get(`${server}/getAuthID?phone=${phone}&uid=${uid}`).subscribe(data => {
      if (data['error']) {
        this.coreService.presentToast(data['error']);
        return;
      }

      document.location.href = data['call'];
      this.db.object(`authHistory/${data['key']}/call_id`).valueChanges().subscribe(value => {
        if (value) {
          firebase.auth().signInWithCustomToken(value as string);
        }
      });
    });
  }

  // выход из учетной записи
  logout() {
    firebase.auth().signOut();
  }
}
