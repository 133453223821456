import { Component, OnInit } from '@angular/core';
import { CoreService } from 'src/app/services/core.service';
import { FavoritesService } from 'src/app/servicesProject/favorites.service';
import {BasketService} from "../../servicesProject/basket.service";

@Component({
  selector: 'app-product',
  inputs: ['item'],
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  item;

  constructor(
    public basketService: BasketService,
    public favoritesService: FavoritesService,
    public coreService: CoreService
  ) { }

  ngOnInit() {
  }

  favoritesAdd($event, item) {
    $event.stopPropagation();
    this.favoritesService.add(item, 1);
  }

  basketAdd($event, item) {
    $event.stopPropagation();
    this.basketService.add(item, 1)
  }

}
