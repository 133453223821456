import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from '../services/auth.service';
import { CoreService, FirebaseData } from '../services/core.service';
import { ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user;

  cacheDetail: { [key: string]: ReplaySubject<FirebaseData> };

  constructor(
    private db: AngularFireDatabase,
    private authService: AuthService,
    private coreService: CoreService,
  ) {
    this.cacheDetail = {};
  }

  detail(id?: string) {
    id = id ? id : `/user/${this.authService.getLogin()}`;

    if (this.cacheDetail[id]) {
      return this.cacheDetail[id].asObservable();
    } else {
      console.log('Запросим сервер детали пользователя', id);
      this.cacheDetail[id] = new ReplaySubject(1);

      CoreService.snapshotChangesObjectToModel(
        this.db.object(id)
      )
        .pipe(
          tap(items => {
            this.cacheDetail[id].next(items);
          })
        ).subscribe();


      return this.cacheDetail[id];
    }
  }

  save(items: FirebaseData): Promise<any> {
    return this.db.object(`/user/${this.authService.getLogin()}`).update(items);
  }

}
