export class ResponseModel {
    key?: string;
    dateCreate: string; // дата создания
    user: string;       // код пользователя
    response: string;   // текст предложения

    isMyResponse: boolean;

    constructor(
        response?: string
    ) {
        if (response) {
            this.response = response;
        }
    }
}
