import { Injectable, NgZone } from '@angular/core';
import { FirebaseData, CoreService } from '../services/core.service';
import { ReplaySubject } from 'rxjs';
import { Storage } from '@ionic/storage';
import { map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from '../services/auth.service';
import * as firebase from 'firebase';
import {User} from "firebase";

@Injectable({
  providedIn: 'root'
})
export class BasketService {

  basket: FirebaseData;
  oldShopID: string;
  basket$: ReplaySubject<FirebaseData> = new ReplaySubject(1);

  constructor(
    private core: CoreService,
    private storage: Storage,
    private db: AngularFireDatabase,
    private authService: AuthService,
    private ngZone: NgZone,
  ) {
    this.storage.ready().then(() => {
      this.storage.get('basket').then(data => {
        console.log('The parameters of the basket have been read', data);
        this.basket = data ? data : {};
        this.refreshBasket();
      });

      this.storage.get('shopID').then(data => {
        if (data) {
          this.oldShopID = data;
        }
      });
    });
  }

  add(item: FirebaseData, count: number) {
      // console.log('Добавим в корзину', item, count, shopID)
      // this.oldShopID = shopID;
      // this.storage.set('shopID', this.oldShopID);

      if (this.basket[item.id]) {
        const oldCount = parseFloat(this.basket[item.id].count);

        this.basket[item.id] = item;
        this.basket[item.id].count = oldCount + count;
      } else {
        this.basket[item.id] = item;
        this.basket[item.id].count = count;
      }
      this.core.presentToast('Товар добавлен в корзину.');
      this.refreshBasket();
  }

  refreshBasket() {
    this.ngZone.run(() => {
      this.basket$.next(this.basket);
    });

    this.storage.set('basket', this.basket);

    if (Object.keys(this.basket).length === 0) {
      this.oldShopID = null;
      this.storage.remove('shopID');
    }
  }

  list() {
    return this.basket$.asObservable()
      .pipe(
        map(items => {
          return Object.keys(items).map(key =>
            items[key]
          );
        })
      );
  }

  changeCount(id: string, count: number) {
    this.basket[id].count = count;
    this.refreshBasket();
  }

  deleteKey(hashmap: FirebaseData, keyRemove: string) {
    return Object.keys(hashmap).reduce((newObj, key) => {
      if (key !== keyRemove) {
        newObj[key] = hashmap[key];
      }

      return newObj;
    }, {});
  }


  delete(id: string) {
    console.log('удаляем', id);
    this.basket = this.deleteKey(this.basket, id);
    this.refreshBasket();
  }

  sendOrder(uid?: string, user?): Promise<any> {
    uid = uid ? uid : this.authService.getLogin()
    const sendOrder = firebase.functions().httpsCallable('sendOrder');
    return sendOrder({
      zakaz: {
        magazine: this.oldShopID,
        user: uid,
        userName: user.name
      },
      zakaztovar: this.basket
    });
  }

  clear() {
    this.basket = {};
    this.refreshBasket();
  }
}
