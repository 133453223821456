import {Component, forwardRef, Input, NgZone} from '@angular/core';
import { UploadFileService } from '../../services/upload-file.service';
import { AlertController } from '@ionic/angular';
import { Router } from '@angular/router';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import {first} from "rxjs/operators";

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FormUpload2Component),
  multi: true
};

@Component({
  selector: 'form-upload2',
  inputs: ['count', 'files', 'isReadOnly'],
  templateUrl: './form-upload2.component.html',
  styleUrls: ['./form-upload2.component.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class FormUpload2Component {
  get value(): any { return this.photoFiles; };
  set value(v: any) {
    if (v !== this.photoFiles) {
      this.photoFiles = v;
      this.onChange(v);
    }
  }

  @Input() isNotFullScreen: boolean;
  count: number; // предельное количество файлов
  isReadOnly: boolean; // режим без редактирования
  isLoadingImage: boolean;
  photoFiles: Array<string>;
  isAllowDownload: boolean; // разрешено загружать еще файлы

  constructor(
    private uploadService: UploadFileService,
    private alertController: AlertController,
    private router: Router,
    private zone: NgZone
  ) {
    this.photoFiles = [];
  }

  changeAllowDownload(isPreload?: boolean) {
    this.isAllowDownload = (this.photoFiles.length < (isPreload ? this.count - 1 : this.count)) && !this.isReadOnly;
  }

  async removeFile(file: string) {
    if (this.isNotFullScreen) {
      const index = this.photoFiles.indexOf(file);
      this.photoFiles.splice(index, 1);
      this.changeAllowDownload();

      return;
    }

    if (this.isReadOnly) {
      this.router.navigate(['/fullsreen-image', file]);
    } else {

      const index = this.photoFiles.indexOf(file);

      const alert = await this.alertController.create({
        header: 'Выберите действие',
        buttons: [{
          text: 'На весь экран',
          handler: () => {
            this.router.navigate(['/fullsreen-image', file]);
          }
        }, {
          text: 'Удалить',
          role: 'destructive',
          handler: () => {
            this.photoFiles.splice(index, 1);
            this.changeAllowDownload();
          }
        }, {
          text: 'Отмена',
          role: 'cancel',
          handler: () => {

          }
        }]
      });

      await alert.present();
    }
  }

  addFilesFake() {
    console.log(2232323)
    document.getElementById('file_uploading').click();
  }

  addFiles(event) {
    const target = event.target || event.srcElement;
    this.isLoadingImage = true;
    console.log(target)
    this.uploadService.saveFileToStorage(target.files[0]).pipe(first()).toPromise().then(fileUrl => {
      console.log(fileUrl)
      this.zone.run(() => {
        this.isLoadingImage = false;
        this.photoFiles.push(fileUrl);
        this.changeAllowDownload();
      })

    }).catch(err => {
      this.isLoadingImage = false;
      console.log(err)
    })
  }

  writeValue(value: any) {
    if (value) {
      this.photoFiles = value;
    } else {
      this.photoFiles = [];
    }

    this.changeAllowDownload();
    this.onChange(this.photoFiles);
  }

  change() {
    this.onChange(this.photoFiles);
  }

  onChange = (_) => { };
  onTouched = () => { };

  registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }
  registerOnTouched(fn: () => void): void { this.onTouched = fn; }
}
