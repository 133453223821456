import { Injectable } from '@angular/core'
import { combineLatest, of, Observable } from 'rxjs'
import * as _ from 'lodash'
import { AngularFirestore, QueryFn, DocumentReference } from '@angular/fire/firestore'
import { switchMap, map, first } from 'rxjs/operators'
import { Params } from '@angular/router'
import { StateService } from '../servicesProject/state.service'

@Injectable({
	providedIn: 'root',
})
export class CorequeryService {
	constructor(private firestore: AngularFirestore, private stateService: StateService) {}

	// список
	listBlock(type: string, queryFn?: QueryFn): Observable<Params[]> {
		return this.stateService.reqWithState$(type, this.firestore.collection(type, queryFn).snapshotChanges()).pipe(
			map(items =>
				items.map(item => ({
					key: item.payload.doc.id,
					...(item.payload.doc.data() as Params),
				}))
			)
		)
	}

	// список группы
	listGroupBlock(type: string, queryFn?: QueryFn): Observable<Params[]> {
		return this.stateService.reqWithState$(type, this.firestore.collectionGroup(type, queryFn).snapshotChanges()).pipe(
			map(items =>
				items.map(item => ({
					key: item.payload.doc.id,
					parent: item.payload.doc.ref.parent.parent.id,
					...(item.payload.doc.data() as Params),
				}))
			)
		)
	}

	combineQuery(params: string[], query: (item: string) => Observable<Params[]>) {
		return combineLatest(params.map(param => query(param))).pipe(
			switchMap((items: Array<Array<any>>) =>
				of(items.reduce((acc, subitems) => _.unionWith(acc, subitems, _.isEqual) as Params[], []))
			)
		)
	}

	// выдает список, поля описанные в categories имеют значение true
	listBlockWhereArray(collection: string, categories: string[]) {
		return this.combineQuery(categories, category => this.listBlock(collection, ref => ref.where(category, '==', true)))
	}

	addBlock(collection: string, params: Params): Promise<DocumentReference> {
		return this.firestore.collection(collection).add(params)
	}

	detailBlock(collection: string, id: string): Observable<Params> {
		return this.stateService
			.reqWithState$(`${collection}/${id}`, this.firestore.collection(collection).doc(id).snapshotChanges())
			.pipe(
				map(item => ({
					key: item.payload.id,
					...(item.payload.data() as Params),
				}))
			)
	}

	saveDetailBlock(collection: string, id: string, params: Params) {
		return this.firestore.collection(collection).doc(id).set(params, { merge: true })
	}

	updateDetailBlock(collection: string, id: string, params: Params) {
		return this.firestore.collection(collection).doc(id).update(params)
	}

	setting(id: string) {
		return this.firestore.collection('settings').doc(id).valueChanges()
	}

	deleteDetailBlock(collection: string, id: string) {
		return this.firestore.collection(collection).doc(id).delete()
	}

	deleteCollection(path) {
		return this.listBlock(path)
			.pipe(first())
			.toPromise()
			.then(data => {
				return data.map(res => {
					this.deleteDetailBlock(path, res.key)
				})
			})
	}
}
