import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from "@ionic/angular";
import {CoreService} from "../../services/core.service";
import {MasterService} from "../../services/master.service";
import {PayService} from "../../servicesProject/pay.service";
import {AuthService} from "../../services/auth.service";
import {first} from "rxjs/operators";

@Component({
    selector: 'app-withdraw',
    templateUrl: './withdraw.component.html',
    styleUrls: ['./withdraw.component.scss'],
})
export class WithdrawComponent implements OnInit {

    @Input() balanceWithdraw;
    @Input() balance;

    amount;
    user;
    card;

    constructor(
        public modalCtrl: ModalController,
        private coreService: CoreService,
        private masterService: MasterService,
        private payService: PayService,
        private authService: AuthService
    ) {
    }

    ngOnInit() {
        this.masterService.detail(this.authService.getLogin())
            .pipe(
                first()
            )
            .toPromise()
            .then(data => {
                this.user = data
            })
    }


    withDraw() {
        console.log(Number(this.amount))
        if (Number(this.amount) == 0 || Number(this.balanceWithdraw) < Number(this.amount)) {
            this.coreService.presentAlert('You requested more than the amount available')
        } else {
            this.masterService.updateShop({
                balance: this.balance - this.amount,
                card: this.card
            }).then(() => {
                this.payService.addTransaction({
                    dateCreate: new Date().toISOString(),
                    sum: this.amount,
                    type: 'minus',
                    card: this.card
                });
                let mailData = {
                    subject: 'Запрос на вывод',
                    message: `<p>Запрос на вывод средств</p>        
                              <p>Номер телефона: ${this.user.phone}</p>
                              <p>Имя продавца: ${this.user.store_name}</p>
                              <p>Сумма вывода: ${this.amount}</p>`
                };
                this.payService.sendEmail(mailData);
                this.coreService.presentAlert('Output request sent')
            })
        }
    }

}
