import { AuthService } from '../services/auth.service';

export class NotifyModel {
    key?: string;
    dateCreate: string;  // Дата создания
    subject: string;  // тема уведомления
    text: string;  // Текст уведомления
    user: string;  // код пользователя
    active: boolean; // true - новое уведомление
    isRead: boolean; // true - уведомление прочитано
    url: string;  // страница для перехода из уведомления
    userCreate: string; // пользователь автор сообщения
    isReadTo: boolean; // true - уведомление прочитано адресатом

    constructor(
    ) {
        this.dateCreate = new Date().toISOString();
        this.active = true;
        this.isRead = false;
    }
}
