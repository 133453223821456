import {Injectable, NgZone} from '@angular/core';
import {CoreService, FirebaseData} from "../services/core.service";
import {BehaviorSubject, ReplaySubject} from "rxjs";
import {Storage} from "@ionic/storage";
import {AngularFireDatabase} from "@angular/fire/database";
import {AuthService} from "../services/auth.service";
import {first, map} from "rxjs/operators";
import * as firebase from "firebase";

@Injectable({
    providedIn: 'root'
})
export class FavoritesService {

    favorites: FirebaseData = {};
    oldShopID: string;
    favorites$: BehaviorSubject<FirebaseData> = new BehaviorSubject({});

    constructor(
        private core: CoreService,
        private storage: Storage,
        private db: AngularFireDatabase,
        private authService: AuthService,
        private ngZone: NgZone,
    ) {
        this.authService.auth().then(uid => {
            if (uid) {
                this.db.list(`favorites/${uid}`)
                    .valueChanges()
                    .pipe(
                        first()
                    )
                    .toPromise()
                    .then(data => {
                        if (data.length) {
                            data.forEach((item: any) => {
                                this.favorites[item.id] = item;
                            })

                        }
                        this.refreshBasket();
                    })
            } else {
                this.refreshBasket();
            }
        });
    }

    add(item: FirebaseData, count: number) {
        try {
            if (this.authService.getLogin()) {
                if (this.favorites[item.id]) {
                    this.delete(item.id);
                    this.core.presentToast('Продукт удалён из избранного');
                } else {
                    this.favorites[item.id] = item;
                    this.favorites[item.id].count = count;
                    this.db.object(`favorites/${this.authService.getLogin()}/${item.id}`).set(item);
                    this.core.presentToast('Продукт добавлен в избранное');
                }

                this.refreshBasket();
            }
        } catch (e) {
            if (this.favorites[item.id]) {
                const oldCount = parseFloat(this.favorites[item.id].count);

                this.favorites[item.id] = item;
                this.favorites[item.id].count = oldCount + count;
            } else {
                this.favorites[item.id] = item;
                this.favorites[item.id].count = count;
            }
            this.core.presentToast('The product has been added to the basket.');
            this.refreshBasket();
        }


    }

    getStatus(item: FirebaseData) {
        if (this.authService.getLogin()) {
            if (this.favorites[item.id]) {
                return true;
            } else {
                return false;
            }
        }
    }

    refreshBasket() {
        this.ngZone.run(() => {
            this.favorites$.next(this.favorites);
        });
    }

    list() {
        return this.favorites$.asObservable()
            .pipe(
                map(items => {
                    return Object.keys(items).map(key =>
                        items[key]
                    );
                })
            );
    }

    changeCount(id: string, count: number) {
        this.favorites[id].count = count;
        this.refreshBasket();
    }

    deleteKey(hashmap: FirebaseData, keyRemove: string) {
        return Object.keys(hashmap).reduce((newObj, key) => {
            if (key !== keyRemove) {
                newObj[key] = hashmap[key];
            }

            return newObj;
        }, {});
    }


    delete(id: string) {
        console.log('удаляем', id);
        this.favorites = this.deleteKey(this.favorites, id);
        this.db.object(`favorites/${this.authService.getLogin()}/${id}`).remove();
        this.refreshBasket();
    }

    clear() {
        this.favorites = {};
        this.refreshBasket();
    }
}
