import { AuthService } from './../../services/auth.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgForm } from '@angular/forms';

import * as core from 'src/app/services/core.service';

import { ResponseModel } from './../../model/response-model';
import { ResponseService } from 'src/app/services/response.service';
import { MasterService } from 'src/app/services/master.service';

@Component({
  selector: 'rtp-responses-list',
  templateUrl: './responses-list.component.html',
  styleUrls: ['./responses-list.component.scss']
})
export class ResponsesListComponent implements OnInit {

  @Input() id: string;
  @Input() detail: core.FirebaseData;

  userAd: string;

  isExistProfileMaster: Boolean;
  list$: core.ObservableFirebaseList;

  constructor(
    private coreService: core.CoreService,
    private responseService: ResponseService,
    private masterService: MasterService,
  ) {
  }

  ngOnInit() {
    this.userAd = this.detail.user;

    this.list$ = this.responseService.list(this.userAd, this.id);
    this.isExistProfileMaster = true;
  }

  onSubmit(form: NgForm) {
    this.masterService.isExistProfileMaster().then(isExist => {
      if (isExist) {
        if (!form.value.response) {
          this.coreService.presentToast('Не заполнено предложение на объявление');
        } else {
          this.onSubmitExistProfile(form);
        }
      } else {
        if (form.value.fio && form.value.phone) {
          this.onSubmitExistProfile(form);
        } else {
          this.coreService.presentToast('Заполните фамилию, имя и телефон для отклика.');
          this.isExistProfileMaster = false;
        }
      }
    });
  }

  onSubmitExistProfile(form: NgForm) {
    const people = {
      fio: form.value.fio,
      phone: form.value.phone
    };

    this.responseService.addResponse(this.id, this.userAd, new ResponseModel(form.value.response))
      .then(() => {
        if (!this.isExistProfileMaster) {
          this.masterService.addPeople(people).then(() => {
            form.reset();
          });
        } else {
          form.reset();
        }
      });

  }
}
