import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { CoreService } from './core.service';
import { Router, Params } from '@angular/router';
import { NotifyModel } from '../model/notify-model';
import { map, switchMap, first } from 'rxjs/operators';

import { Subject, ReplaySubject, combineLatest, of, Observable } from 'rxjs';
import { FirebaseX } from '@ionic-native/firebase-x/ngx';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  public static typeNotifications = 'notifications';  // Уведомления
  public static typePush = 'push';                    // Токены для отправки Push

  notifications = 'notifications';

  private countBadge: ReplaySubject<any>;
  storageMarkRead: ReplaySubject<Params>;

  constructor(
    private db: AngularFireDatabase,
    private authService: AuthService,
    private coreService: CoreService,
    private firebasePlugin: FirebaseX,
    private router: Router,
    private plt: Platform,
    private translateService: TranslateService,
    private storage: Storage,
  ) {
    this.countBadge = new ReplaySubject(1);
    this.storageMarkRead = new ReplaySubject(1);
    this.storage.get('storageMarkRead').then(data => {
      this.storageMarkRead.next(data || {});
    });
  }

  // Инициализация Push-уведомлений без условий
  // на входе массив топиков на которые нужно подписаться или пустой массив
  initPush(topicsEng?: Array<string>) {
    this.firebasePlugin.getToken().then(token => {
      this.saveTokenPush(token);
    });

    this.firebasePlugin.onTokenRefresh().subscribe(token => {
      this.saveTokenPush(token);
    });

    this.firebasePlugin.onMessageReceived().subscribe(data => {
      if (data.tap === 'background') {
        this.router.navigateByUrl(data.url);
      } else {
        // this.coreService.presentToast('Новое уведомление');
      }
    });
  }

  // Сохранение токена для отправки Push-уведомлений
  saveTokenPush(token: string) {
    this.authService.auth().then(value => {
      this.db.object(NotificationService.typePush + '/' + this.authService.getLogin()).update({
        'token': token
      });
    });
  }

  // Запись уведомления пользователя
  updateNotify(category: string, userID: string, notify: NotifyModel): Promise<void> {
    return new Promise((resolve, reject) => {
      this.translateService.get(notify.subject).subscribe(async (res: string) => {
        notify.subject = res;

        console.log('updateNotify', `${category}_${notify.user}`);
        this.db.object(`${this.notifications}/${userID}/${category}_${notify.user}`)
          .update(notify)
          .then(() => {
            resolve();
          });
      });
    });
  }

  listDialogs() {
    return this.db
      .list(`${this.notifications}/${this.authService.getLogin()}`
        , ref => ref.orderByChild('subject').equalTo('Новое сообщение')
        // , ref => ref.orderByChild('subject').equalTo('Новое предложение')
      )
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.val() as NotifyModel;
            data['key'] = a.payload.key;
            return data;
          })
        )
      );
  }

  listPushAll(): Observable<NotifyModel[]> {
    return this.storageMarkRead
      .pipe(
        switchMap(mark =>
          this.db.list('PushAll')
            .snapshotChanges()
            .pipe(
              map(actions =>
                actions.map(a => {
                  const data = a.payload.val() as NotifyModel;
                  data['key'] = a.payload.key;
                  data.isRead = mark[data['key']];
                  return data;
                })
              )
            )
        )
      );
  }

  listCombine() {
    return combineLatest([
      this.listPushAll(),
      this.list()
    ])
      .pipe(
        switchMap((items: Array<Array<any>>) =>
          of(
            items.reduce((acc, subitems) =>
              [...acc, ...subitems], [])
          )
        )
      );
  }

  list() {
    return this.db
      .list(`${this.notifications}/${this.authService.getLogin()}`
        // , ref => ref.orderByChild('subject').equalTo('Новое сообщение')
        // , ref => ref.orderByChild('subject').equalTo('Новое предложение')
      )
      .snapshotChanges()
      .pipe(
        map(actions =>
          actions.map(a => {
            const data = a.payload.val() as NotifyModel;
            data['key'] = a.payload.key;
            return data;
          })
        )
      );
  }

  // Подписка на количество непрочитанных уведомлений
  getBadge(): Subject<number> {
    return this.countBadge;
  }

  // Отметка о прочтении уведомления
  setMarkRead(key: string) {
    const subscription = this.db.object(`${this.notifications}/${this.authService.getLogin()}/${key}`)
      .valueChanges().subscribe(value => {
        subscription.unsubscribe();

        if (value) {
          if ((value as NotifyModel).subject) {
            this.db.object(`${this.notifications}/${this.authService.getLogin()}/${key}`)
              .update({
                isRead: true
              });
          }
        }
      });
  }

  // Отметка о прочтении общего уведомления
  setAllPushMarkRead(item: Params) {
    if (item.subject === 'Общее уведомление') {
      this.storageMarkRead
        .pipe(first())
        .subscribe(res => {
          res[item.key] = true;
          this.storage.set('storageMarkRead', res);
          this.storageMarkRead.next(res);
        });
    }
  }

  // инициализация системы уведомлений
  initNotify() {
    if (this.plt.is('ios')) {
      this.firebasePlugin.hasPermission().then((hasPermission) => {
        if (!hasPermission) {
          this.firebasePlugin.grantPermission().then((grantPermission) => {
            console.log(grantPermission);
          });
        }
      });
    }

    this.authService.state.subscribe(authData => {
      const notifyListNew = this.db.list(NotificationService.typeNotifications + '/' + this.authService.getLogin(), ref => {
        return ref.orderByChild('active').equalTo(true);
      }).snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const id = a.payload.key;
          const data = a.payload.val() as NotifyModel;
          data.key = id;

          this.coreService.presentToast('Новое уведомление');

          this.db.object(NotificationService.typeNotifications + '/' + this.authService.getLogin() + '/' + data.key).update({
            active: false
          });

          return { id, ...data };
        }))
      );
      notifyListNew.subscribe();

      const notifyListNoRead = this.db.list(NotificationService.typeNotifications + '/' + this.authService.getLogin(), ref => {
        return ref.orderByChild('isRead').equalTo(false);
      }).snapshotChanges().pipe(
        map(actions => actions.map(a => {
          const id = a.payload.key;
          const data = a.payload.val() as NotifyModel;
          data.key = id;
          return { id, ...data };
        }))
      );
      notifyListNoRead.subscribe((value: any) => {
        value.messageCount = 0;
        value.adsCount = 0;

        console.log('Количество непрочитанных уведомлений: ' + value.length);
        this.countBadge.next(value.length);
        this.firebasePlugin.setBadgeNumber(value.length);
      });
    });
  }
}
