import { Injectable } from '@angular/core'
import { Observable, BehaviorSubject, of } from 'rxjs'
import { filter, tap, switchMap, mergeMap } from 'rxjs/operators'
@Injectable({
	providedIn: 'root',
})
export class StateService {
	private _rootState: { [url: string]: BehaviorSubject<any> } = {}

	private createState<T = any>(url: string, value: T): void {
		this._rootState[url] = new BehaviorSubject<T>(value)
	}

	public setState<T>(url: string, value: T): void {
		const state$ = this._rootState[url]
		if (!state$) return this.createState(url, value)
		this._rootState[url].next(value)
	}

	public getState<T>(url: string): Observable<T> {
		const state$ = this._rootState[url]

		const stateValue = state$ === null || state$ === undefined ? undefined : state$.value
		return !stateValue ? of(null) : this._rootState[url].asObservable()
	}

	public reqWithState$<T>(url: string, req$: Observable<T>): Observable<T> {
		const modReq$ = req$.pipe<T>(
			mergeMap(value => {
				this.setState(url, value)
				return this.getState<T>(url)
			})
		)

		return this.getState(url).pipe(switchMap(res => (res ? this.getState<T>(url) : modReq$)))
	}
}
