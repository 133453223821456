import { Injectable } from '@angular/core'
import { AngularFireDatabase } from '@angular/fire/database'
import { BehaviorSubject, Observable, of } from 'rxjs'
import { FirebaseData, CoreService, ObservableFirebaseList } from '../services/core.service'
import { map, mergeMap, switchMap, tap } from 'rxjs/operators'
import { AuthService } from '../services/auth.service'
import { CorequeryService } from '../services/corequery.service'
import { el } from '@angular/platform-browser/testing/src/browser_util'
import { FavoritesService } from './favorites.service'
import { BasketService } from './basket.service'
import { StateService } from './state.service'

@Injectable({
	providedIn: 'root',
})
export class TovarsService {
	filter$: BehaviorSubject<{
		brand: string
		size: Array<{ value: string; check: boolean }>
		priceFrom: any
		priceTo: any
		sort: string
	}> = new BehaviorSubject<{
		brand: string
		size: Array<{ value: string; check: boolean }>
		priceFrom: any
		priceTo: any
		sort: string
	}>({
		brand: '',
		priceFrom: '',
		priceTo: '',
		sort: 'date',
		size: [
			{ value: '5 US', check: false },
			{ value: '5.5 US', check: false },
			{ value: '6 US', check: false },
			{ value: '6.5 US', check: false },
			{ value: '7 US', check: false },
			{ value: '7.5 US', check: false },
			{ value: '8 US', check: false },
			{ value: '8.5 US', check: false },
			{ value: '9 US', check: false },
			{ value: '9.5 US', check: false },
			{ value: '10 US', check: false },
			{ value: '10.5 US', check: false },
			{ value: '11 US', check: false },
			{ value: '11.5 US', check: false },
			{ value: '12 US', check: false },
			{ value: '12.5 US', check: false },
			{ value: '13 US', check: false },
			{ value: '14 US', check: false },
			{ value: '14.5 US', check: false },
			{ value: '15 US', check: false },
			{ value: '15.5 US', check: false },
			{ value: '16 US', check: false },
			{ value: '16.5 US', check: false },
			{ value: '17 US', check: false },
			{ value: '17.5 US', check: false },
		],
	})

	constructor(
		private db: AngularFireDatabase,
		private authService: AuthService,
		private coreService: CoreService,
		public favoritesService: FavoritesService,
		public basketService: BasketService,
		private readonly stateService: StateService
	) {}

	// Список категорий магазинов
	listCategories(): Observable<string[]> {
		return this.stateService
			.reqWithState$('categories', this.db.list('categories').valueChanges())
			.pipe(map(items => items.map(item => item as string)))
	}

	// список магазинов в категории
	listShops(category: string): ObservableFirebaseList {
		return this.stateService.reqWithState$(
			category,
			CoreService.snapshotChangesListToModel(
				this.db.list('shop', ref => ref.orderByChild(`category${category}`).equalTo(true))
			)
		)
	}

	// список всех магазинов
	listAllShops(): ObservableFirebaseList {
		return this.stateService.reqWithState$(
			'shop',
			CoreService.snapshotChangesListToModel(this.db.list('shop', ref => ref))
		)
	}

	// Информация о магазине
	detailShop(id: string): Observable<FirebaseData> {
		return CoreService.snapshotChangesObjectToModel(this.db.object(`shop/${id}`))
	}

	// список товаров магазина
	listTovars(id?: string, category?: string): ObservableFirebaseList {
		return this.stateService
			.reqWithState$('tovars', CoreService.snapshotChangesListToModel(this.db.list(`tovars`)))
			.pipe(tap(res => console.log('tovars', res)))
			.pipe(
				map((data: any) => {
					return data
						.map((item: any) => {
							let itemsIds = Object.keys(item)
							return Object.values(item).filter((i: any, index) => {
								if (i && i.category) {
									i.id = itemsIds[index]
									return i
								}
							})
						})
						.flat()
				}),
				mergeMap(data => {
					return this.favoritesService.favorites$.pipe(
						map(favorites => {
							return data.map(item => {
								item.favorite = !!favorites[item.id]
								return item
							})
						})
					)
				})
			)
	}

	listTovarsSearch(id?: string, category?: string): ObservableFirebaseList {
		return this.stateService
			.reqWithState$('tovars', CoreService.snapshotChangesListToModel(this.db.list(`tovars`)))
			.pipe(
				map((data: any) => {
					return data
						.map((item: any) => {
							let itemsIds = Object.keys(item)
							return Object.values(item).filter((i: any, index) => {
								if (i && i.category) {
									i.id = itemsIds[index]
									return i
								}
							})
						})
						.flat()
				}),
				mergeMap(data => {
					return this.favoritesService.favorites$.pipe(
						map(favorites => {
							return data.map(item => {
								item.favorite = !!favorites[item.id]
								return item
							})
						})
					)
				})
			)
	}

	// список товаров магазина
	listTovarsFilter(id?: string, filter?: any): ObservableFirebaseList {
		let tovars$ = this.stateService
			.reqWithState$('tovars', CoreService.snapshotChangesListToModel(this.db.list(`tovars`)))
			.pipe(
				map(data => {
					return data.map((item: any) => {
						let itemsIds = Object.keys(item)
						return Object.values(item).filter((i: any, index) => {
							//console.log(i)
							if (i.underCategory) {
								i.id = itemsIds[index]
								return i.underCategory.key == id
							}
						})
					})
				}),
				map((data: any) => data.flat()),
				mergeMap(data => {
					return this.favoritesService.favorites$.pipe(
						map(favorites => {
							return data.map(item => {
								item.favorite = !!favorites[item.id]
								return item
							})
						})
					)
				})
			)

		return filter.asObservable().pipe(
			switchMap((search: any) =>
				tovars$.pipe(
					map(items =>
						items.filter(item => {
							return (
								this.filterItems(search.brand, item.model) &&
								this.filterPrice(item.price, search.priceFrom, search.priceTo) &&
								this.filterArr(search.size, item.size)
							)
						})
					),
					map(items => {
						console.log(search.sort)
						if (!search.sort) {
							return items
						} else {
							return items.sort((a, b) => {
								const dc1 = a.price
								const dc2 = b.price

								if (search.sort == 'date') {
									return <any>new Date(b.dateCreate) - <any>new Date(a.dateCreate)
								} else if (search.sort == 'max') {
									if (dc2 > dc1) {
										return 1
									}
									if (dc2 < dc1) {
										return -1
									}

									return 0
								} else if (search.sort == 'min') {
									if (dc1 > dc2) {
										return 1
									}
									if (dc1 < dc2) {
										return -1
									}

									return 0
								}
							})
						}
					})
				)
			)
		)
	}
	// список товаров магазина в категории
	listTovarsCategoryFilter(id?: string, filter?: any): ObservableFirebaseList {
		let tovars$ = this.stateService
			.reqWithState$('tovars', CoreService.snapshotChangesListToModel(this.db.list(`tovars`)))
			.pipe(
				map(data => {
					return data.map((item: any) => {
						let itemsIds = Object.keys(item)
						return Object.values(item).filter((i: any, index) => {
							if (i.category) {
								i.id = itemsIds[index]
								return i.category.key == id
							}
						})
					})
				}),
				map((data: any) => data.flat()),
				mergeMap(data => {
					return this.favoritesService.favorites$.pipe(
						map(favorites => {
							return data.map(item => {
								item.favorite = !!favorites[item.id]
								return item
							})
						})
					)
				})
			)

		return filter.asObservable().pipe(
			switchMap((search: any) =>
				tovars$.pipe(
					map(items =>
						items.filter(item => {
							return (
								this.filterItems(search.brand, item.model) &&
								this.filterPrice(item.price, search.priceFrom, search.priceTo) &&
								this.filterArr(search.size, item.size)
							)
						})
					),
					map(items => {
						console.log(search.sort)
						if (!search.sort) {
							return items
						} else {
							return items.sort((a, b) => {
								const dc1 = a.price
								const dc2 = b.price

								if (search.sort == 'date') {
									return <any>new Date(b.dateCreate) - <any>new Date(a.dateCreate)
								} else if (search.sort == 'max') {
									if (dc2 > dc1) {
										return 1
									}
									if (dc2 < dc1) {
										return -1
									}

									return 0
								} else if (search.sort == 'min') {
									if (dc1 > dc2) {
										return 1
									}
									if (dc1 < dc2) {
										return -1
									}

									return 0
								}
							})
						}
					})
				)
			)
		)
	}

	// список товаров магазина в мужское/женское
	listTovarsGenderFilter(gender?: string, filter?: any): ObservableFirebaseList {
		let tovars$ = this.stateService
			.reqWithState$('tovars', CoreService.snapshotChangesListToModel(this.db.list(`tovars`)))
			.pipe(
				map(data => {
					return data.map((item: any) => {
						let itemsIds = Object.keys(item)
						return Object.values(item).filter((i: any, index) => {
							console.log(i)
							if (i.gender) {
								i.id = itemsIds[index]
								return i.gender === gender || i.gender === 'all'
							}
						})
					})
				}),
				map((data: any) => data.flat()),
				map(data => {
					return data.map(item => {
						item.favorite = Object.values(this.favoritesService.favorites)
							.map(el => el.id)
							.includes(item.id)
						return item
					})
				})
			)

		return filter.asObservable().pipe(
			switchMap((search: any) =>
				tovars$.pipe(
					map(items =>
						items.filter(item => {
							return (
								this.filterItems(search.brand, item.model) &&
								this.filterPrice(item.price, search.priceFrom, search.priceTo) &&
								this.filterArr(search.size, item.size)
							)
						})
					),
					map(items => {
						console.log(search.sort)
						if (!search.sort) {
							return items
						} else {
							return items.sort((a, b) => {
								const dc1 = a.price
								const dc2 = b.price

								if (search.sort == 'date') {
									return <any>new Date(b.dateCreate) - <any>new Date(a.dateCreate)
								} else if (search.sort == 'max') {
									if (dc2 > dc1) {
										return 1
									}
									if (dc2 < dc1) {
										return -1
									}

									return 0
								} else if (search.sort == 'min') {
									if (dc1 > dc2) {
										return 1
									}
									if (dc1 < dc2) {
										return -1
									}

									return 0
								}
							})
						}
					})
				)
			)
		)
	}

	filterPrice(price, priceFrom, priceTo) {
		if (priceFrom && priceTo) {
			return price > priceFrom && price < priceTo
		} else if (priceFrom && !priceTo) {
			return price > priceFrom
		} else if (priceTo && !priceFrom) {
			return price < priceTo
		} else {
			return true
		}
	}

	filterArr(search, size) {
		let arr = search.filter(item => item.check)
		if (arr.length) {
			let fil = arr.filter(item => item.value == size)
			return !!fil.length
		} else {
			return true
		}
	}

	filterItems(search, item) {
		return search ? search == item : true
	}

	add(items: FirebaseData) {
		items.dateCreate = new Date().toISOString()
		items.shop = this.authService.getLogin()
		return this.db.list(`/tovars/${this.authService.getLogin()}`).push(items)
	}

	update(items: FirebaseData) {
		return this.db.object(`/tovars/${this.authService.getLogin()}/${items.key}`).update(items)
	}

	// список моих товаров
	listMyTovars(): ObservableFirebaseList {
		return CoreService.snapshotChangesListToModel(this.db.list(`tovars/${this.authService.getLogin()}`))
	}

	deleteTovar(id: string) {
		this.coreService.presentAlertInButton('Удалить товар?', 'Предупреждение').then(() => {
			this.db.object(`tovars/${this.authService.getLogin()}/${id}`).remove()
		})
	}

	// Информация о товаре
	detailTovar(id: string, shopID?: string): Observable<FirebaseData> {
		if (!shopID) {
			shopID = this.authService.getLogin()
		}
		console.log(`tovars/${shopID}/${id}`)
		return CoreService.snapshotChangesObjectToModel(this.db.object(`tovars/${shopID}/${id}`))
	}
}
